.customers-page-container {
    width: 95vw;
    padding: 0px 25px 0px 25px;

    .customers-page-content-container {
        height: 65vh;
        overflow-y: scroll;
  
        /* width */
        &::-webkit-scrollbar {
        width: 5px !important;
        height: 30px !important;
        }
    
        /* Track */
        &::-webkit-scrollbar-track {
        background: #36404A;
        }
    
        /* Handle */
        &::-webkit-scrollbar-thumb {
        background: #dee2e6;
        }
    
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
        background: #1ABC9C;
        }
    }


    .ant-tag {
        margin: 0 !important;
    }
   

    .margin-right {
        margin-right: 10px;
    }
    .customers-table {
        overflow: hidden;

    }

    .customers-table-container {
        background-color: #36404A;
        padding: 20px;
        // height: 90vh;
        // overflow: scroll;


        /* width */
    &::-webkit-scrollbar {
        width: 5px !important;
        height: 30px !important;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #313841;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #dee2e6;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #1ABC9C;
    }
    }
    .table-head-row {
        padding: 20px;
        background-color: #3F4A56;
        margin-top: 20px;
        margin-bottom: 5px;
        border-top: 1px solid #495561;
        border-bottom: 1px solid #495561;
        min-width: 900px;

        .table-head-text {
            color: #dee2e6;
        }
    }

    .customers-row {
        padding: 20px;
        min-width: 900px;

        background-color: #313841;
        margin-bottom: 5px;
        .table-content-text {
            color: #dee2e6;

        }
    }

}
        @media only screen and (max-width: 600px) {
            .customers-page-container {
                padding: 20px 10px 10px 10px;
                .top-nav {
                    display: none;
                }
                .customers-table-container{
                    padding-top: 0px;
                }
            }
        }




.slide-in{
    // transform: translateX(-100%);
    width: 0px;
    -webkit-animation: slide 0.5s forwards;
    animation: slide 0.2s forwards;
}

@-webkit-keyframes slide {
    // 100% { transform: translateX(0px); }
    100% {width : 100%}
}

@keyframes slide {
    // 100% { transform: translateX(0px); }
    100% {width : 100%}
}