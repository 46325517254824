.products-mobile-card-container {
  .ant-btn {
    height: 40px;
  }

  .ant-collapse-item-active {
    border: 1px solid #1abc9c;
  }

  .ant-collapse-item {
    margin-bottom: 10px;
  }
}
