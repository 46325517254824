.products-container {
  width: 95%;
  //
  padding-right: 0px;
  overflow-y: hidden;

}


.table-container-parent {
  padding: 20px 20px 20px 20px;
  background-color: #36404a;

  // min-width: 800px;
  // margin-top: 100px !important;
  .products-table-heading {
    margin-bottom: 10px;
  }

  .products-table-content {
    width: 100%;
    height: 65vh;
    overflow-y: scroll;

    /* width */
    &::-webkit-scrollbar {
      width: 5px !important;
      height: 30px !important;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #36404A;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #dee2e6;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #1ABC9C;
    }
  }
}

// Ant Collapse
.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-extra {
  float: none;
}

.ant-collapse>.ant-collapse-item.ant-collapse-no-arrow>.ant-collapse-header {
  padding: 0;
}

.ant-collapse {
  border: none;
}

.ant-collapse>.ant-collapse-item {
  border: none;
}

.ant-collapse-content {
  border: none;
}

.top-nav-table-container {
  margin-bottom: 20px;
}

.breadcrumb-icon {
  font-size: 0.8em !important;
}

.filter-btn {
  background-color: #36404a !important;
}

.card-container-parent {
  display: none;
}

.right-space {
  padding-right: 20px;
}

.hover-icon:hover {
  color: #1ABC9C !important;
}

.base-product-container {

  .ant-collapse-item-active {
    border: 1px solid #1ABC9C;

  }
}

.border-is-active {
  border: 1px solid #1ABC9C;
}

@media only screen and (max-width: 600px) {
  .table-container-parent {
    display: none;
  }

  .table-container {
    display: none;
  }

  .card-container-parent {
    display: block;
  }

  .products-container {
    padding-top: 10px;
    // background-color: #313841 !important;
  }

  .products-top-nav {
    display: none;
  }

  .products-top-nav {
    display: none;
  }
}