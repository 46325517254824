@font-face {
  font-family: "AvenirNext";
  src: local("AvenirNext"),
    url(../../fonts/AvenirNextLTPro-Regular.otf) format("opentype");
}

.platform-content-page-container {
  font-family: "Muli", sans-serif;
  font-size: 16px;

  .ant-layout-footer {
    background-color: white;
  }

  .ant-input-lg {
    background-color: white;
    border: 1px solid #adadad;
  }
  .text-center {
    text-align: center;
  }
  .margin-zero {
    margin: 0px !important;
  }
  .text-color-1 {
    color: black;
  }
  .text-color-2 {
    color: #1abc9c;
  }
  .text-color-3 {
    color: white;
  }
  .text-grey {
    color: #8599ad;
  }
  .bold-font {
    font-weight: 800;
  }
  .font-weight-400 {
    font-weight: 400;
  }
  .font-weight-300 {
    font-weight: 300;
  }
  .display-block {
    display: block;
  }

  // .font-size-1{
  //     font-size: 1.1em;
  // }
  #components-layout-demo-top .logo {
    width: 120px;
    height: 31px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px 24px 16px 0;
    float: left;
  }
  .logo {
    .ant-typography {
      color: black;
    }
  }

  .ant-layout-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 0.5px solid rgba(128, 128, 128, 0.233);
  }
  .ant-menu-horizontal {
    border: none;
  }
  .ant-menu-item {
    border-bottom: none;
    &:hover {
      border: none;
    }
  }
  .ant-menu-item-selected {
    border-bottom: none;
  }
  .ant-menu-item-active {
    border-bottom: none;
  }
  .demo-btn {
    border-color: #1abc9c;
    color: #1abc9c;
  }

  .ant-layout-content {
    background-color: white;
    padding: 0px !important;
    // padding: 0 50px;
  }

  .hero-section {
    margin: 0px !important;
    padding: 100px 300px;

    .ant-typography {
      margin: 0px;
      //   font-weight: bold;
    }
  }

  .hero-img-section {
    padding: 0px 90px 90px 90px;
    border-bottom: 0.5px solid #8a8a8a;

    .hero-img-2 {
      max-width: 1200px;
    }
  }

  .brand-section {
    padding: 50px 100px;
    .ant-typography {
      margin-bottom: 80px;
    }
    .brand-logo {
      width: 140px;
    }
  }

  .img-right-content-section {
    margin: 0px !important;
    padding: 0 180px;

    .ant-typography {
      margin: 0px;
    }

    .img-right {
      width: 850px;
    }
  }

  .img-left-content-section {
    margin: 0px !important;
    padding: 0 180px;

    .ant-typography {
      margin: 0px;
    }

    .img-left {
      width: 850px;
    }
  }

  .review-section {
    padding: 50px 300px;
    border-top: 0.5px solid #8a8a8a;
    border-bottom: 0.5px solid #8a8a8a;
  }

  .left-list-section {
    padding: 50px;

    .assignment-img {
      width: 100%;
    }
    .anticon-check-circle {
      font-size: 1.3em;
      color: #1abc9c;
    }
  }
  .book-demo-section {
    padding-top: 80px;
    border-top: 1px solid #adadad;
  }
  .feature-section {
    padding: 80px;

    .feature-card {
      border: 1px solid #e4e8ee;
      border-radius: 5px;
      padding: 20px;
      transition: all 200ms ease-in-out;

      &:hover {
        border: 1px solid #1abc9c;
      }
      h4 {
        color: #1abc9c;
      }
    }
  }

  .info-footer-container {
    padding: 0px 100px;
    background-color: #f1f1f1;
    .info-footer {
      border-top: 1px solid #adadad;
      border-bottom: 1px solid #adadad;
      padding: 50px 0;
    }
  }
}
