.scroll {
  overflow-x: scroll;
}

.ant-upload-select-picture-card {
  width: 100% !important;
  background-color: #36404a !important;
  border: 1px dashed rgb(69, 82, 95) !important;
}

.ant-upload-select-picture-card:hover {
  border-color: gray !important;
}

.ant-upload {
  height: 105px !important;
}

.hide-on-all-images {
  .ant-upload-select-picture-card {
    display: none;
  }
}

.ant-upload-extra-text{
  color : #FF8291;
  margin-top : 2px;
}

.upload-select{
  .ant-upload-list{
    .ant-upload{
      span{
        height: 150px !important;
      }
    }
  }
}