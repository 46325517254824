.filter-btn {
    background-color: #36404a !important;
  }

.table-header{
    background-color: #3F4A56 !important;
}

.list-table-dealshub-products-content {
  height: 65vh;
    overflow-y: scroll;
  
    /* width */
    &::-webkit-scrollbar {
      width: 5px !important;
      height: 30px !important;
    }
  
    /* Track */
    &::-webkit-scrollbar-track {
      background: #36404A;
    }
  
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #dee2e6;
    }
  
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #1ABC9C;
    }
}

.slide-in-left{
  transform: translateX(100%);
  // width: 0px;
  -webkit-animation: slide-in-left 0.5s forwards;
  animation: slide-in-left 0.2s forwards;
}

@-webkit-keyframes slide-in-left {
  100% { transform: translateX(0px); }
  // 100% {width : 100%}
}

@keyframes slide-in-left {
  100% { transform: translateX(0px); }
  // 100% {width : 100%}
}

// .slide-out-right{
//   transform: translateX(0px);
//   // width: 0px;
//   -webkit-animation: slide-out-right 0.5s forwards;
//   animation: slide-out-right 0.2s forwards;
// }

// @-webkit-keyframes slide-out-right {
//   100% { transform: translateX(100%); }
//   // 100% {width : 100%}
// }

// @keyframes slide-out-right {
//   100% { transform: translateX(100%); }
//   // 100% {width : 100%}
// }