.bundle-pi-page-container {
  width: 95vw;
  padding: 0px 25px 0px 25px;

  .bundle-pi-container {
    background-color: #36404a;
    padding: 20px;
    min-height: 80vh;
  }

  .ant-tag {
    margin: 0 !important;
  }

  .margin-right {
    margin-right: 10px;
  }
  .customers-table {
    overflow: hidden;
  }
}
@media only screen and (max-width: 600px) {
  .bundle-pi-page-container {
    padding: 20px 10px 10px 10px;
    .top-nav {
      display: none;
    }
    .customers-table-container {
      padding-top: 0px;
    }
  }
}
