.product-export-modal {
    a {
        color: #1abc9c ;
        &:hover {
            color: white ;
        }
    }
    .border-is-active {
        border: 1px solid #1abc9c !important;
        border-radius: 2px;
    }
    
    .ant-upload {
        max-height: 32px !important;
    }
}