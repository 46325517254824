.customer-card{
    cursor: pointer;
    background-color : #233140 !important;

    .ant-card-body{
        background-color : #233140 !important;
    }
}

.customer-card:hover{
    border-color: #1abc9c94;
}
.customer-details-collapse{
    .ant-collapse-item{
        .ant-collapse-header{
            color : #54BD9D !important;
        }
    }
}

.customer-details-tabs {
    .ant-tabs-bar{
        background: #233140;
        border-bottom: transparent;
        color: white;

        .ant-tabs-nav-wrap{
            .ant-tabs-tab{
                font-size: 12px;
            }
        }
    }

    .ant-tabs-tabpane{
        background-color: #233140;
        padding: 20px;
    }
}

.tag-button-primary{
    font-size: 11px;
    background-color: #20605F !important;
    border : 1px solid #54BD9D;
    width: 100%;
}
  