.invoice-container {
    width: 100vw;
    padding: 0px 25px 25px 25px;
    overflow: hidden;
    
    .ant-tag {
        margin: 0 !important;
    }
   

    .margin-right {
        margin-right: 10px;
    }

    .invoice-content-container {
        background-color: #36404A;
        padding: 20px;
        height: 100vh;
        overflow-x: scroll;
        .invoice-content {
            min-width: 900px;

        }
        

        .text-color-1 {
            color: #98a6ad;
            margin: 0px !important;
        }
        .text-color-2 {
            color: #dee2e6;
        }
        .text-color-3 {
            color: #adb5bd;
        }

        .product-table {
            .table-head {
                padding: 15px;
                border-top: 1px solid #495561;
                border-bottom: 1px solid #495561;
            }
            .table-content-row {
                padding:15px;
                border-bottom: 1px solid #495561;
            }
        }
    }
    
    
}



@media only screen and (max-width: 600px) {
    .invoice-container {
        padding: 10px 10px 10px 10px;
        .top-nav {
            display: none;
        }
        .customers-table-container{
            padding-top: 0px;
        }
    }
}
