// .ant-upload {
//   height: 0px !important;
// }

.selectFlyerForm {
  span {
    .ant-upload {
      height: 0px !important;
    }
  }
}
