.ant-collapse-header
{
    color:white !important;
}

.editorbox-label {
    color: rgba(255, 255, 255, 0.6) 
}

.vitalselect{
.ant-select-selection{
    background: #21262a;
    border-color: #32383d !important;
    border: 1px solid #32383d !important;
    border-radius: 4px;
}
}