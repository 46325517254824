.gutter-example .ant-row > div {
  // background: #36404A;
  border: 0;
}

.gutter-box-1 {
  background: #36404a;
  padding: 5px 18px 0px 18px;
  border-radius: 5px;
}

.gutter-colorless-block {
  padding: 5px 0;
  border-radius: 5px;
}

.save-btn-edit-product-page {
  margin-bottom: 100px;
}

.DarkerTheme {
  background: #292f35 !important;

  .ant-col {
    .ant-collapse {
      background: #292f35 !important;

      .ant-collapse-item {
        .ant-collapse-content {
          .ant-collapse-content-box {
            .ant-row {
              .ant-col {
                input {
                  background: #21262a !important;
                  border-color: #32383d !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

.ant-select-selection {
  background: #3c4853;
  border: 1px solid rgb(69, 82, 95);
}

.disabledSelect {
  .ant-select-selection {
    background: #2f3841 !important;
    border: 1px solid #45525f !important;
    border-radius: 4px;
    color: #a5adb0 !important;
  }
}

.calendar-bgcolor {
  .ant-calendar-picker-input.ant-input {
    background-color: #3c4853;
    border: 1px solid rgba(255, 255, 255, 0.3);
  }
}

.quill {
  border: 1px solid rgba(255, 255, 255, 0.3) !important;
}

.ql-toolbar.ql-snow {
  border: none !important;
}

.switch-color {
  margin-left: 24px;
  .ant-switch-checked {
    background-color: #52c41a !important;
  }
}

.title-Product__Manual {
  font-size: 1.3em;
  color: white;
  margin-bottom: 15px;
  opacity: 0.8;
}
