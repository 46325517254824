.slider-preview {
  
    img {
      width: 100%;
      height: auto;
      object-fit: contain;
      background-color: white;
    }
  
    .slick-arrow {
    //   display: none !important;
    }
    .slick-slide {
        height: auto !important;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
    }
  }
  .slick-track {
    display: flex !important;
    justify-content: space-between !important;
  }
  
  .slider-bar {
    .slick-track {
        width: 100% !important;
        .slick-slide {
            height: auto !important;
            background-color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 2px;
        }
    }
    .slick-active {
      // display: flex;
      // justify-content: center;
      // align-items: center;
      // padding: 5px;
      z-index: 1;
    }
    
  }
  
  .silder-img-div{
    width: 100%;
    padding: 5px !important;

    .slick-track {
        width: 100% !important;
    }
  
    img{
      width: 100%;
      max-height: 100px;
      margin: 0px auto;
      object-fit: contain;
      background-color: white;
    }
  }

//   .react-imageMagnify div{
//     &:last-of-type {
//         // position: absolute !important;
//         // top: 100 !important; 
//         // left: 100 !important;
//         // z-index: 1 !important;
//         overflow: visible !important;
//         left: 50% !important;
       
//     }
//   }
// .react-magnified-image {
//     position: fixed !important;
//     top: 0 !important;
//     left: 50% !important;
//     z-index: 1 !important;
// }
  