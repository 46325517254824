.products-container-2 {
    width: 95%;
    height: 90vh;
    background-color: #36404a;
  }


  .publish-product-container{
    width: 95vw;
    padding: 0px 25px 0px 25px;
    .publish-product-container-containt{
      background-color: #36404A;
      padding: 20px;
      min-height: 90vh;
    }
  }
  
  .product-basket-text{
    margin-top: 20px !important;
    margin-bottom: 10px !important;
  }

  .product-basket{
    background-color:  #313841;
    padding: 20px;
    padding-bottom: 26px;
    max-height: 40vh;
    overflow: scroll;
  }

  .publish-product-table{
    background-color:  #313841;
    padding: 20px;
    padding-bottom: 26px;
    height: 90vh;
    overflow: scroll;
  }

  .publish-product-upload{
    span{
      .ant-upload{
        width: 100%;
      }
    }
  }

  .hide{
    display: none;
  }

  .publish-product-left-table{
    .header{
      padding: 12px;
      background-color: #3F4A56;
      color: #D0E1E5;
      border-top: 1px solid #495561;
      border-bottom: 1px solid #495561;
    }
    .table-row{
      padding: 12px;
      background-color: #3C4853;
      margin-top: 10px;
    }
  }

  .rules{
    margin-bottom: 20px;
    .ant-collapse{
      //color of header

      .ant-collapse-item{
        .ant-collapse-header{
          padding: 12px 12px 12px 40px;
        }
        .ant-collapse-content{
          .ant-collapse-content-box{
          padding: 20px;}
        }
      }
    }
  }

  .header-text{
    font-size: 12px;
    color: white;
  }

  .row-text{
    font-size: 12px;
  }