.product-list-filter-modal-container {
    .ant-calendar-picker {
        .ant-calendar-picker-input {
           
        }
    }
    .ant-badge{
        width: 100%;
    }
}
.filter-modal-btn {
    background-color: yellowgreen !important;
}

.filter-modal {
    .ant-modal-content {
        width: 500px;
    }
}