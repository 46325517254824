// =============================
// this is were we start
// =============================
*,
*:before,
*:after {
  box-sizing: border-box;
}

.anticon-menu-fold {
  font-size: 0.8em;
}
.anticon-menu-unfold {
  font-size: 0.8em;
}

.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active,
.ant-select-open .ant-select-selection {
  border-color: #d9d9d9;
  box-shadow: none;
}

.ant-input:focus {
  box-shadow: none;
}

.ant-collapse-arrow {
  color: #1abc9c !important;
}
.anticon-down {
  color: #1abc9c !important;
}
.hideitsandeep {
  display: none;
}
.text-color3 {
  color: #dee2e6;
}

.ant-input-lg {
  background-color: #3b4853;
}

.ant-input-number {
  border: 1px solid rgb(69, 82, 95);
  border-radius: 4px;
  background-color: #3b4853;
  width: 70%;
  &:hover {
    border: 1px solid rgb(69, 82, 95);
  }
  &:active {
    border: 1px solid rgb(69, 82, 95);
  }
}

.purple-btn {
  background-color: #6658dd;
  border: none;
  color: white;

  &:hover {
    background-color: #4938d7;
    color: white;
  }
}

@media screen and (max-width: 768px) {
}
