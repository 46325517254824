.srv-validation-message {
  display: inline;
}

.ant-input-disabled {
  color: rgba(255, 255, 255, 0.6) !important;
  background-color: rgba(0, 0, 0, 0.13) !important;
}

.ant-input-group-addon {
  background-color: rgba(0, 0, 0, 0.13) !important;
  color: rgba(255, 255, 255, 0.6) !important;
  border: none;
}
