.dealshub-admin-banners-container {
    width: 95vw;
    height: 95vw;
    padding: 0px 25px 0px 25px;
    margin: 20px 0px;

    .banners-wrapper {
        height: 95vw;

        .collapse-div {
            margin: 0px 0px 10px 0px;

            .ant-collapse {
                padding: 20px 8px;

                .content-container {
                    background: #36404A;
                    padding: 20px;
                }
            }
        }
    }

    .banners-preview {
        height: 95vw;
        background-color: #fff;
        border-radius: 5px;

        .banner-preview-top-bar {
            height: 50px;
            background: #DEDEDE;
            border-radius: 5px;

            .preview-btn {
                display: inline-block;
                margin: 15px 0px 15px 5px;
                cursor: pointer;
            }

            .close-btn {
                width: 10px;
                height: 10px;
                background: red;
                border-radius: 50%;
            }

            .minimize-btn {
                width: 10px;
                height: 10px;
                background: yellow;
                border-radius: 50%;
            }

            .expand-btn {
                width: 10px;
                height: 10px;
                background: green;
                border-radius: 50%;
            }
        }

        .coming-soon-wrapper {
            margin: 35vh auto 0px 10vw;

            h1 {
                margin: 0 auto !important;
                display: inline;
            }

        }
    }
}