.gutter-example .ant-row > div {
  // background: #36404A;
  border: 0;
}
.gutter-box {
  background: #36404a;
  padding: 5px 0;
  border-radius: 5px;
}

.gutter-colorless-block {
  padding: 5px 0;
  border-radius: 5px;
}

.gutter-row {
  background-color: #313841;
}

