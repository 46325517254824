.ant-tooltip-inner{
    padding : 12px
}

.ant-drawer-wrapper-body{
    background-color: #36404a;
    overflow: hidden !important;
}

.ant-tabs-bar{
    border: none;
}

.OrderHistoryRow{
    padding : 16px 20px !important;
    width: 100%;
}

//.OrderHistoryRow:hover{
//    // border : 1px solid #69C7A4 !important;
//    border: 1px solid #1ABC9C;
//}

.file-status-select{
    color : #1abc9c !important;
    font-size: 14px !important;
    .ant-select-selection{
        height : 25px !important;
        background-color: #1abc9c2b !important;
    }
    .ant-select-selection__rendered{
        margin-right : 30px !important;
        .ant-select-selection-selected-value{
            line-height: 25px !important;
        }
    }
}

// .ant-tooltip-arrow{
//     i{
//         color: white;   
//     }
// }