.company-profile-page-main-container {
    width: 95vw;
    padding: 0px 25px 0px 25px;

    .company-page-sub-container {
        background-color: #36404A;
        margin-top: 0px !important;

        .ant-input-group-wrapper {
            border-radius: 5px
        }

        .ant-input-group-addon {
            background-color: #313841;
            border: none
        }

        .rc-color-picker-wrap {
            width: 20px;
            height: 20px;
        }

        .label {
            color: rgba(255, 255, 255, 0.6);
        }

        textarea {
            background-color: rgb(60, 72, 83);
        }
    }
}

.spinner-wrapper {
    text-align: center;
    font-size: 100px;
    position: relative;
    height: 100vh;
    top: 50px;
}

@media only screen and (max-width: 600px) {

    .company-page-sub-container {
        margin-top: 0px !important;
    }
    .company-profile-page-main-container {
        margin-top: 0px !important;
    }
}

@media only screen and (max-width: 1000px) {
    .company-profile-page-main-container {
        margin-top: 30px !important;
    }
}