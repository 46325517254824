.text-ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-select-selection__clear{
  position: absolute;
  top: 50%;
  right: 11px;
  z-index: 1;
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-top: -6px;
  color: #1ABC9C;
  font-size: 18px;
  font-style: normal;
  line-height: 18px;
  text-align: center;
  text-transform: none;
  background: #36404a;
  cursor: pointer;
  opacity: 1;
}

.collapse-container-account-manager {
  padding: 6px 0px 6px 0px !important;
}