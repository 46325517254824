
.admin-activity-history-page-container {
    width: 95vw;
    padding: 0px 25px 0px 25px;
    .admin-activity-history-table-container {
        background-color: #36404A;
        height: 90vh;
    }
}

@media only screen and (max-width: 600px) {

   .admin-activity-history-page-container {
    padding: 10px 10px;
   }
}

@media only screen and (max-width: 1000px) {
    .admin-activity-history-page-container {
        padding: 25px 10px;
       }
}