.dealshub-admin-section-category-deletion-in-progress-container {
    margin: 50px auto;
    text-align: center;
}

.dealshub-admin-collapse-panel {

    .added-products-listing {

        .promotional-product-price-wrapper {
            margin: 12px 0px;
        }

        .ant-spin-nested-loading {
            min-height: 100px;
        }

        .ant-spin-nested-loading>div>.ant-spin .ant-spin-text {
            color: #fff;
            font-weight: bold;
            text-shadow: none;
        }
    }

    .ant-calendar-range-picker-input:placeholder-shown {
        cursor: default;
    }

    .ant-calendar-picker-icon {
        color: #fff;
        cursor: pointer;
    }

    .products-price-wrapper {
        color: #fff;
    }
}