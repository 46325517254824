.factory-card {
  border: 1px solid #45525f;
  border-radius: 4px;

  .ant-card {
    background-color: transparent;
  }

  .ant-card-body {
    padding: 10px;
    background: #313841;
    padding-top: 0px !important;
    border: none;
  }
}
