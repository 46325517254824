.order-requests {
  padding: 20px;
  margin-bottom: 10px;
  width: 100%;

  .order-request-title {
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;

    .header-title {
      color: white;
      text-align: left;
    }
  }
  .row-header {
    font-size: 16px;
    font-weight: 500;
    color: white;
  }
  .request-collapse {
    padding: 10px 20px;
    //margin: 10px 0 0;
    width: 100%;
    .panel-header {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0px !important;
      padding: 10px;
      line-height: 2;

      .text {
        color: #F1F1F1;
        font-weight: 400;
        font-size: 16px;
      }
      //text-align: center;
    }
    .unit-requests {
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 2;
      //text-align: center;
      padding: 20px 10px;
      margin-top: 5px;
      border-top: 1px solid #818181;
      .text {
        color: white;
        font-size: 18px;
        font-weight: bold;
      }
      .small-font {
        font-size: 16px;
      }
      .text-ellipses {
        width: 450px;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
      }
      .white-text {
        color: white;
      }
      .image-container {
        width: 70px;
        height: 70px;
      }
    }
  }
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra  {
  float: none;
}