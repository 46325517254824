.content-health-container {
  width: 100%;
  max-height: 30vh;
  overflow-y: scroll;

  /* width */
  &::-webkit-scrollbar {
    width: 5px !important;
    height: 30px !important;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #36404A;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #dee2e6;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #1ABC9C;
  }
}