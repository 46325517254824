
#PaymentForm {

    .rccs {
        margin: 0px !important;
    }
}
.customer-details-page {
    width: 95vw;
    padding: 0px 25px 0px 25px;

    .customer-details-page-content {
        .personal-details-card {
            background-color: #37414B;
            padding: 20px;
            margin: 0px !important;

            .user-avatar-container {
                margin-bottom: 30px;

                i {
                    color: black;
                }
                .ant-avatar-icon {
                    background-color: white;
                    margin-bottom: 10px;
                }
            }
        }
        .order-cart-tab-container {
            background-color: #37414B;
            padding: 20px;
            margin-bottom: 20px;
        }

        .sessions-container {
            background-color: #37414B;
            padding: 20px;
            margin: 0px !important;
            .ant-collapse-header {
                display: flex;
                justify-content: space-between;
            }
            .ant-collapse {
                padding: 20px;
                background-color: #313841;
            }
        }
    }

    .order-details-container {
        margin-top: 10px;
        height: 70vh;
        overflow-y: scroll;

          /* width */
          &::-webkit-scrollbar {
            width: 5px !important;
            height: 0px !important;
          }
        
          /* Track */
          &::-webkit-scrollbar-track {
            background: #36404A;
          }
        
          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: #dee2e6;
          }
        
          /* Handle on hover */
          &::-webkit-scrollbar-thumb:hover {
            background: #1ABC9C;
          }
        .order-details-row {
            background-color: #313841;
            padding: 20px;
            margin-bottom: 15px !important;
        }
    }


}





@media only screen and (max-width: 600px) {

    .customer-details-page {
        padding: 0 10px;
    }
}

@media only screen and (max-width: 1000px) {
 
}