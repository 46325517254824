.collapse-container-account-manager {
    padding: 6px 0px 6px 0px !important;
}

.orders-account-manager-mobile-view-page-container{
    margin-left: 10px !important;
    margin-top: 10px !important;
}

.collapse-header-activity {
    width: 95% !important;
    background-color: #36404A;
}

.logs-list-titles{
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 0px !important;
}

.diff-icon{
    color: whitesmoke;
    font-size: 16px;
    margin-right: 5px;
}

.log-details-modal{
    min-width: 1000px !important;
    margin-left: 300px;
}

.values-type-header{
    color: white;
    font-size: 16px;
    font-weight: bold;
}

.log-details-modal-row{
    min-width : 900px;
}

.instance-dump-col{
    min-height: 420px;
    max-height: 420px;
    overflow-y: scroll;
    padding-left: 15px;
}