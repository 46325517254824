//general
.flex-center{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px !important;
}

//styles
.customer-collapse-panel {
    padding: 20px;
    .ant-collapse-header {
      display: flex;
      justify-content: space-between;
    }
}

.overlay-menu{
    height: 100px;
    overflow-y: scroll;
}

.button{
    width: 100%;
}

.address-container{
    height:500px;
    overflow-y: scroll;
    .address-card{
        margin:5px 0 0;
        border-radius:4px;
        box-sizing: border-box !important;
        cursor: pointer;
        .address-card-button{
            margin-right: 5px;
            float: right;
        }
    }
}

.customer-avatar{
    border-radius:50%; 
    height:100px; 
    width:100px; 
    background-color:#1abc9c;
    text-align:center;
    color:White;
    font-size:30px;
}

.input{
    margin: 10px 0 10px;
}
.ant-input-number-handler-wrap {
    width: 0px;
}
