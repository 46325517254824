.Categoryimage-page-container {
    width: 95vw;
    padding: 0px 25px 0px 25px;
    position: relative;
    .ant-tag {
      margin: 0 !important;
    }
    .top-nav {
      margin-bottom: 20px !important;
      padding-left: 10px;
    }
    .text-color-1 {
      color: #dee2e6;
    }
  
    .Categoryimage-table-container {
      background-color: #36404a;
      padding: 20px;
      
      overflow: hidden;
      .categoryimage-table {
        overflow: scroll;
        height: 75vh;
        padding : 0px 10px;
        .categoryimage-card {
          padding: 5px !important;
          .categoryimage-card-content {
            background-color: #313841;
            padding: 15px !important;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .categoryimage-page-container {
      padding: 20px 10px 10px 10px;
      .top-nav {
        display: none;
      }
      .categoryimage-table-container {
        padding-top: 0px;
      }
    }
  }
  
  .categoryimage-avatar {
    img {
      object-fit: contain;
      background: white;
    }
  }
  p{
    margin-left: 4px;
    padding-left: 4px;
  }
  .modalmaindetailedimage{
    padding-left: -4px;
    // margin-left: -4px;

  }