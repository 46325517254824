.ant-card-head {
  background: rgb(49, 56, 65);
  border: none;
}
.ant-card-body {
  background: rgb(49, 56, 65);
  padding-top: 0px !important;
  border: none;
}
.ant-card-head-title {
  color: #dee2e6;
}

.ant-card-bordered {
  border: none;
}

.ant-divider {
  background: rgb(73, 85, 97);
}

.productcardexportpage {
  background: #36404a;
  .ant-card-body {
    background: #36404a !important;
    .ant-card-meta-detail {
      margin-top: 20px;
    }
  }
}

.categoryproductcard {
  width: 100%;
  position: relative;

  .ant-card-body {
    background: #3c4853 !important;
    padding: 10px !important;
    // div {
    //   &:nth-child(0) {
    //     right: 30px !important;
    //     position: absolute;
    //     top: 2px;
    //   }
    // }

    .ant-row {
      .ant-col {
        img {
          width: 100%;
          border-radius: 4px;
        }
      }
    }
  }
}

.export-card {
  background-color: transparent;
  .ant-card-body {
    padding: 10px;
  }
  .ant-card-head {
    padding: 0 10px;
  }
}

.custom-card-2 {
  background-color: #36404a;
  padding: 16px;
}

.draft-pi-modal {
  .ant-collapse-item {
    .ant-collapse-header {
      padding-top: 20px;
      padding-right: 20px;
      padding-bottom: 20px;
      .ant-collapse-extra {
        float: right;
      }
    }

    .ant-collapse-content {
      // padding: 20px;
    }
  }
}

.myCont {
  padding: 20px;
}

.r-card-key{
  font-weight: bold;
  margin-bottom: 0px !important;
  padding-right: 5px;
}

.r-card-value{
  margin-bottom: 0px !important;
}

.br{
  margin-bottom: 10px !important;
}

.ant-card{
  background-color: transparent !important;
  padding: 5px;

  .ant-card-body{
    padding: 10px !important;
  }
}