.factory-products-page-container {
    width: 95vw;
    padding: 0px 25px 0px 25px;

    .ant-tag {
        margin: 0 !important;
    }
   

    .margin-right {
        margin-right: 10px;
    }
    .factory-products-table {
        overflow: scroll;

    }

    .factory-products-table-container {
        background-color: #36404A;
        padding: 20px;
        height: 90vh;
        overflow: hidden;
    }
    .table-head-row {
        padding: 20px;
        background-color: #3F4A56;
        margin-top: 20px;
        margin-bottom: 5px;
        border-top: 1px solid #495561;
        border-bottom: 1px solid #495561;
        min-width: 900px;

        .table-head-text {
            color: #dee2e6;
        }
    }

    .factory-products-row {
        padding: 20px;
        min-width: 900px;

        background-color: #313841;
        margin-bottom: 5px;
        .table-content-text {
            color: #dee2e6;

        }
    }

}
        @media only screen and (max-width: 600px) {
            .factory-products-page-container  {
                padding: 20px 10px 10px 10px;
                .top-nav {
                    display: none;
                }
                .factory-products-table-container{
                    padding-top: 0px;
                }
            }
        }




.pi-modal{
    .ant-modal-content{
        .ant-modal-body{
            padding-top : 0px !important;
            padding-right : 0px !important;
            padding-left : 0px !important;
        }
    }
}

// .draft-pi-modal{
//     .ant-collapse-item-active{
      
//             .ant-collapse-content-active{
//                 .ant-collapse-content-box{
//                     width : "0px";
//                     height : "0px"
//                 }
//             }
        
//     }
// }

.my-row-for-draft-pi-modal{
    padding: 20px;
    background-color: #36404A;
    margin-bottom: 10px;
}

.factory-products-content{
    width: 100%;
    height: 65vh;
    overflow-y: scroll;
    overflow-x: hidden;

    /* width */
    &::-webkit-scrollbar {
      width: 5px !important;
      height: 30px !important;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #36404A;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #dee2e6;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #1ABC9C;
    }
}