

.activity-history-table {
    .ant-btn-link {
        color: #1ABC9C;
    }
}
        .json-viewer-container-old {
            span {
                color: #f1556c !important;
            }
        }
        .json-viewer-container-new {
            span {
                color: #1ABC9C !important;
            }
        }

        