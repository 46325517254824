.image_picker {
  width: 100%;

  .responsive {
    position: relative;
    margin-right: 20px;
    margin-bottom: 10px;
    float: left;
    width:150px;
    height:150px;
    opacity: 0.8;

    .thumbnail {
      border: 1px solid #ddd; /* Gray border */
      border-radius: 4px;  /* Rounded border */
      padding: 5px; /* Some padding */
      width: 150px; /* Set a small width */
      opacity: 1;
      margin: 0;

      &:hover {
        box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.7);
      }
    }

    .selected {
      background-color:null;
    }
    
    &:hover {
      cursor: pointer;
    }

    .checked {
      display: none;
    }
  }

  .selected {
    opacity: 1;

    .checked {
      display: inline-block;
      transition: .5s ease;
      position: absolute;
      bottom: 0;
      right: 0;

      .icon {
        background: url("../../../../media/checkBox.png");
        background-size: cover;
        height: 25px;
        width: 25px;
      }
    }
  }

  .clear {
    clear: both;
  }
}