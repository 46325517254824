.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
  float: right;
}

//.ant-collapse-header {
//  font-size: 22px;
//  font-weight: 700;
//}

.ant-collapse-content {
  overflow: visible;
}

.ant-modal-content {
  //height: 434px;
  width: 846px;
}