// ================================
// General style
// ================================

// Root Element
html,
body {
  min-height: 100vh;
  padding: 0;
}

html {
  font-size: 14px;
  font-family: $font-family;
  scroll-behavior: smooth;
  letter-spacing: 0.04em;
  line-height: 1.5;
}

body {
  background: $bg;
  color: $text;
}

// Heading elements
h1 {
  font-size: 3.6rem;
  line-height: 1.25;
}
h2 {
  font-size: 2.8rem;
  line-height: 1.3;
}
h3 {
  font-size: 2.2rem;
  letter-spacing: -0.08rem;
  line-height: 1.35;
}
h4 {
  font-size: 1.8rem;
  letter-spacing: -0.05rem;
  line-height: 1.5;
}
h5 {
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 1.4;
}
h6 {
  font-size: 1.4rem;
  letter-spacing: 0;
  line-height: 1.2;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

// Paragraph Elements
p {
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 400;
}

//  Anchor Elements
a {
  font-size: 1rem;
  text-decoration: none;
  color: $text2;
}
//  Active Anchor Elements
.active {
  font-size: 1.1rem;
  font-weight: 700;
}

// List Elements
li {
  text-decoration: none;
}

// Align items
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}

// Form elements
.form-control {
  display: block;
  width: 100%;
  padding: 0.8rem 0.8rem;
  border: 0;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 0.05em;
}

// Responsive image
.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

//Buttons  styles
.btn {
  border-radius: 30px;
  padding: 1.1rem 2.2rem;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 1.2rem;
}

.btn2 {
  border-radius: 30px;
  padding: 1.1rem 2.2rem;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 1rem;
}
.btn-primary {
  background: $primary;
  color: #fff;
  font-weight: 500;
}
.btn-primary2 {
  background: $primary;
  color: #fff;
  font-weight: 500;
  margin: 1rem 1rem 0 0;
  transition: all 200ms ease-in-out;
  &:hover {
    background: $primary-dark;
    color: rgb(207, 207, 207);
  }
}
.btn-secondary {
  border: 2px solid $primary;
  font-weight: 700;
  color: $secondaryLight;
  transition: all 200ms ease-in-out;
  &:hover {
    background: $primary;
    color: #fff;
  }
}

// Center Elemets within its parent
.center-child {
  display: grid;
  place-items: center;
}

// Background colors for sections
.bg-primary {
  background: $bg;
}

// Container
.container {
  width: 80%;
  margin: auto;
  overflow: hidden;
}

.container2 {
  width: 90%;
  margin: auto;
  overflow: hidden;
}

// Flex
.flex {
  display: flex;
}

// Grid
.grid {
  display: grid;
}

.center-div-horizontally {
  margin: 0 auto;
}

.center-div-vertically {
  margin: 0 auto;
}

.center-image {
  display: block;
  margin: 0 auto;
}

.signup-container {
  padding: 30px 0px;
}

.center-text {
  text-align: center;
}

.form-container {
  width: 80%;
  max-width: 400px;
}

.make-full-width {
  width: 100%;
}

header {
  font-weight: 700;
  color: #333;
  font-size: 1.785rem;
}

.heading-title {
  font-size: 1.785rem;
  margin: 0px;
}

.submit-btn {
  width: 100%;
}

.toggle-pass {
  top: 10px;
  right: 14px;
  position: absolute;
  cursor: pointer;
}

.center-child {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.margin-right {
  margin-right: 10px;
}
.margin-right-5 {
  margin-right: 5px;
}
.margin-bottom {
  margin-bottom: 10px;
}
.margin-top {
  margin-top: 10px;
}
.margin-bottom-20 {
  margin-bottom: 20px;
}
.padding-left {
  padding-left: 10px;
}
.padding-right {
  padding-right: 10px;
}



@media all and (max-width: 992px) {
  html {
    font-size: 12px;
  }
}

@media all and (max-width: 768px) {
  html {
    font-size: 10px;
  }
}
