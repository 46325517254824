
.ant-modal-body{
    background:  #313841 !important;
  }

  .ant-modal-header{
      background: #36404A !important;
        border: none;
  }
  
  .ant-modal-title{
      color: white !important;
  }

  .ant-modal-footer{
      background: #36404A !important;
      border: none;
  }

  .ant-modal-close:hover{
      color:#313841;
  }

  .export-card-modal{
      .ant-modal-body{
          height: 60vh;
          overflow: scroll;
      }
  }