.custom-datePicker {
    background-color: #3B4853;
    border:1px solid rgb(69, 82, 95);
    border-radius: 5px;
    width: 100%;
    height: 40px;
    padding-left: 10px;

    &:focus {
        outline: 0;
    }
}
.react-datepicker-wrapper {
    width: 100%;
}