.card {
  min-width: 300px;
  min-height: 120px;
  padding: 8px;
  background-color: #36404a;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .title {
    font-size: 1.2em;
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: 8px;
  }

  .amountTitle {
    font-size: 0.85em;
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: 5px;
  }

  .amount {
    font-size: clamp(80%, 1.05em, 1.2rem);
    color: white;
    font-weight: bold;
    font-family: "Courier New", Courier, monospace;
    span {
      font-weight: normal;
      font-size: 0.9em;
      font-family: Arial, Helvetica, sans-serif;
      opacity: 0.8;
    }
  }

  .ordersTitle {
    @extend .amountTitle;
  }

  .orders {
    @extend .amount;
  }
}
