html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

@font-face {
  font-family: "bold font";
  font-weight: bold;
  src: url("fonts/lato/Lato-Bold.ttf") format("truetype");
}

body {
  margin: 0px;
  font-family: "Roboto", sans-serif;
}
p {
  margin: 0px;
}
.center-element {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pi-page-container {

  min-width: 20cm;
  max-width: 80vw;
  min-height: 29.7cm;
  margin: 40px auto;

    // background: red ;

  .save-btn {
    background-color: #4c9a2a;
    /* width:"100%"; */

    color: white;
    border: none;

    &:hover {
      background-color: #4c9a2a;
      color: white;
      border: none;
    }
  }

  .print-btn {
    background-color: #68bb59;
    /* width:"100%"; */

    color: white;
    border: none;

    &:hover {
      background-color: #68bb59;
      color: white;
      border: none;
    }
  }

  .download-btn {
    background-color: #f1556c;
    /* width:"100%"; */

    color: white;
    border: none;

    &:hover {
      background-color: #f1556c;
      color: white;
      border: none;
    }
  }

  .custom-datePicker {
    background-color: white;
    border: none;
    color: blue;
    height: 23px;
  }

  .ant-typography {
    margin-bottom: 0px;
  }

  .anticon-edit {
    color: blue;
  }
  .ant-typography-edit-content {
    // color: blue;
  }
  .ant-input {
    color: blue;
  }
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  text-align: center;
  padding: 10px;
  margin-bottom: 30px;
}

nav .nav-title-container {
  width: 100%;
  // margin-left: 290px;
}

.nav-title-container p {
  font-weight: 700;
  margin: 0px;
  font-size: 1.6em;
}
nav .logo {
  position: absolute;
  top: 5px;
}
.logo img {
  width: 150px;
  height: auto;
}

.details-cards-container {
  display: flex;
  justify-content: space-between;
}

.card-title {
  font-family: "bold font";
  font-size: 1.1em;
  // margin-bottom : 5px;
}
.details-cards-left-container {
  width: 49%;
  background-color: white;
  // min-height: 500px;
}
.details-cards-right-container {
  width: 49%;
  background-color: white;
  // min-height: 500px;
}

.details-card {
  border: 1px solid black;
  // border-radius: 5px;
  margin-bottom: 20px;
}
.details-card-nav {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid black;
  // background-color: #f8f8f8;
}

.details-card-list-container {
  /* list-style-type: square; */
  padding: 10px;
  margin: 0px;
}
.details-card-list {
  display: flex;
  margin-bottom: 1px;
}


.list-title {
  width: 45%;
  font-size: 12px;
  // font-family: "bold font";
  font-weight: bold;
}
.list-value {
  width : 55%;
  font-weight: 500;
  font-size: 12px;
  display: inline;
  color: #403e3e;
}


// for invoice details
.list-title-id {
  width: 35%;
  font-size: 12px !important;
  font-weight: bold !important;
}
.list-value-id {
  width : 65%;
  font-weight: 500;
  font-size: 12px;
  display: inline;
  color: #403e3e;
}


// for shipment details
.list-title-sd {
  width: 35%;
  font-size: 12px;
  // font-family: "bold font";
  font-weight: bold;
}
.list-value-sd {
  width : 65%;
  font-weight: 500;
  font-size: 12px;
  display: inline;
  color: #403e3e;
}


// for bank details
.list-title-bd {
  width: 32%;
  font-size: 12px;
  // font-family: "bold font";
  font-weight: bold;
}
.list-value-bd {
  width : 58%;
  font-weight: 500;
  font-size: 12px;
  display: inline;
  color: #403e3e;
}


.important-notes-container {
  padding: 10px;
}

.product-table {
  border: 1px solid black;
  // border-radius: 5px;
  margin-bottom: 20px;
}
.product-table-head {
  border-bottom: 1px solid black;
  display: flex;
  background-color: #f5c60b;
}
.product-table-head-text {
  font-weight: bold;
  font-size: 1em;
}

.product-table-head-serial-container {
  width: 5%;
  border-right: 1px solid black !important;
  padding: 10px 10px;
}

.product-table-head-products-container {
  width: 63%;
  border-right: 1px solid black !important;
  padding: 10px 10px;
  display: flex;
  align-items: center;
}
.product-table-head-quantity-container {
  width: 8%;
  border-right: 1px solid black !important;
  padding: 10px 10px;
}
.product-table-head-unit-container {
  width: 8%;
  border-right: 1px solid black !important;
  padding: 10px 10px;
}
.product-table-head-price-container {
  width: 8%;
  border-right: 1px solid black !important;
  padding: 10px 10px;
}
.product-table-head-amount-container {
  width: 8%;
  padding: 10px 10px;
}
.product-table-body {
  display: flex;
}
.product-table-footer {
  padding: 10px;
  border-top: 1px solid black;
  display: flex;
}
.product-table-footer .label-container {
  width: 20%;
}
.product-table-footer .value-container {
  width: 80%;
}
.product-table-products-container {
  width: 63%;
  border-right: 1px solid black !important;
  padding: 10px 10px;
  display: flex;
}

.product-table-products-container .img-container {
  width: 10%;
  img{
    width : 100% !important;
    // height : 100% !important;
  }
}
.product-table-products-container .label-container {
  width: 45%;
  padding: 0px 0px 0px 20px;
}
.product-table-products-container .label-container p {
  // margin-bottom: 5px;
  font-size: 12px;
  font-weight: bold;
}


.product-table-products-container .value-container {
  width: 55%;
}
.product-table-products-container-2 .value-container-2 {
  width: 10%;
  margin-right: 10px;
}
.product-table-products-container-2 .value-container-2 .list-value {
  margin-bottom: 5px;
}
.product-table-products-container-2 .img-container img {
  width: 100px;
  height: auto;
}

.signature-container {
  border: 1px solid black;
  // border-radius: 5px;
  margin-bottom: 20px;
  display: flex;
}
.signature-left-container {
  width: 50%;
  border-right: 1px solid black;
  min-height: 200px;
}
.signature-left-container .signature-title {
  font-weight: bold;
  font-size: 1.1em;
}

.signature-right-container {
  width: 50%;
  min-height: 150px;
}
.signature-right-container .signature-title {
  font-weight: bold;
  font-size: 1.1em;
}
.signature-title-container {
  border-bottom: 1px solid black;
  padding: 10px;
}

.product-specification-nav {
  display: flex;
  background-color: #ffc403;
  border-radius: 5px;
  margin-bottom: 20px;
}
.product-details {
  padding: 20px 20px;
  width: 55%;
}
.specification {
  padding: 20px 0px;

  width: 20%;
}
.other-details {
  padding: 20px 0px;

  width: 25%;
}

.product-specification-content {
  border: 1px solid black;
  border-radius: 5px;
  padding: 20px 0px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.product-image-container {
  padding-left: 20px;
  width: 10%;
}
.product-image-container img {
  width: 100px;
  height: auto;
}
.product-specification-container-1 {
  width: 45%;
  padding-left: 20px;
}
.product-specification-container-2 {
  width: 20%;
}
.product-specification-container-3 {
  width: 25%;
}

.product-content-box-1 {
  display: flex;
  width: 100%;
  margin-bottom: 5px;
}
.product-content-box-1 .left-box {
  width: 30%;
}
.product-content-box-1 .right-box {
  width: 70%;
}
.right-box-text {
  font-weight: 500;
  font-size: 1em;
}

.product-content-box-2 {
  display: flex;
  width: 100%;
  margin-bottom: 5px;
}
.product-content-box-2 .left-box {
  width: 50%;
}
.product-content-box-2 .right-box {
  width: 50%;
}

.product-content-box-3 {
  display: flex;
  width: 100%;
  margin-bottom: 5px;
}
.product-content-box-2 .left-box {
  width: 50%;
}
.product-content-box-2 .right-box {
  width: 50%;
}

.instructions-container {
  border: 1px solid black;
  // border-radius: 5px;
  margin-bottom: 20px;

  .anticon-edit {
    color: blue;
  }
  .ant-input {
    color: blue;
  }
  .ant-typography {
    color: black;
  }
}
.important-notes-lists {
  margin: 0px;
}

.important-notes-lists li {
  margin-bottom: 5px;
}


.list-key-big-table{
  width: 50%;
}

.list-value-big-table{
  width : 50%;
  font-weight: 500 !important;
  color : #403e3e;
}

.bullet{
  color :#ffc403;
  margin-right: 2px;
}

.text-center{
  // text-align: center !important;
  // font-size: 5rem;
  // margin-bottom: .5rem;
  //   font-weight: bold;
  //   line-height: 1.2;
  //   display: block;
}

.view-pi-controllers{
  padding: 5px;
  margin-top: 5px !important;
}

.label-container {
  .table-footer-label{
    font-size: 12px;
    font-weight: bold;
  }
}

.value-container{
  .list-value-footer{
    font-size: 12px;
    color : #403e3e;
  }
}


#tnc{
  color : #403e3e;
}