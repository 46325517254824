.card {

    margin: 20px 0px;

  .header {
    display: flex;
    justify-content: space-between;
    .heading {
      font-size: 1.3em;
      color: white;
      opacity: 0.8;
    }

    .selectedDropdown {
      color: white;
      font-size: 1.05em;
      opacity: 0.8;
      border: 1px solid rgba(255, 255, 255, 0.7);
      border-radius: 5px;
      padding: 5px 10px;
    }
  }

  .dataTable{
      margin: 15px 0px;
  }
}

.nothingCard{
    margin: 10px 0px;
    width: 100%;
    background-color: #3b4853;
    padding: 50px;
    min-height: 150px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    .eye{
        color: white;
        opacity: 0.8;
        font-size: 2em;
    }

    .text{
        font-size: 1.2em;
        opacity: 0.8;
        color: white;
    }
}