.order-details-page-container {
  width: 100%;
  padding: 0px 10px 0px 10px !important;
  .ant-tag {
    margin: 0 !important;
  }

  .text-color-1 {
    color: #dee2e6;
  }
  .text-color-2 {
    color: #dee2e6;
  }

  .transaction-collapse {
    padding: 10px;
    border-bottom: 1px solid #495561;
    border-top: 1px solid #495561;
    background-color: #313841;
  }

  .order-details-card-container {
    overflow: scroll;
    background-color: #3f4a56;
    padding: 20px;
    // border: 1px solid #495561;
    border-bottom: 1px solid #495561;
    border-top: 1px solid #495561;
    // border-radius: 5px;
    .order-details-card-nav {
      border-bottom: 1px solid #495561;
      padding-bottom: 10px;
      margin-bottom: 10px;
    }
    .order-summery-container {
      padding: 20px;
      background-color: #313841;
      margin-bottom: 5px;
    }
    .ordered-products-container {
      background-color: #313841;
      padding: 20px;
      margin-bottom: 5px;
    }
  }

  .order-details-container {
    background-color: #36404a;
    padding: 20px;
    // height: 90vh;
    overflow: hidden;

    .search-select {
      margin-right: 10px;
    }
  }
  .product-description-container {
    padding-left: 35px;
  }
}

@media (max-width: 600px) {
  .order-details-page-container {
    padding: 20px 10px 10px 10px;
    .top-nav {
      display: none;
    }
    .order-details-container {
      padding-top: 0px;
      padding: 0px !important;
      //   overflow-x: scroll;
    }
    .order-details-card-container {
      //   min-width: 800px;
    }
    // .invoice-btn {
    //   margin-right: 20px;
    // }

    .product-description-container {
      padding: 0px;
    }
  }
}


.order-details-collapse-panel{
  .ant-collapse-header{
    padding : 20px 20px 20px 40px !important;
    margin-bottom: 20px;
  }

  .ant-collapse-content{
    .ant-collapse-content-box{
      padding : 10px 40px 40px 40px !important;
    }
  }
}