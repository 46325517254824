.dealshubadmincontainer {
    width: 95vw;
    height: 95vw;
    padding: 0px 25px 0px 25px;
    margin: 20px 0px;

    .admin-category-container{
        height: 95vw;
        margin-top: 20px;

        .collapse-div {
            margin: 0px 0px 10px 0px;

            .ant-collapse {
                padding: 20px 8px;

                .content-container {
                    background: #36404A;
                    padding: 20px;
                }
            }
        }
    }
}