.order-cancellation {
	padding: 20px;
	margin-bottom: 10px;
	width: 100%;
	.order-cancellation-title {
		text-align: left;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		padding: 10px;
		.header-title {
			color: white;
			text-align: left;
		}
	}
	.button {
		width: 100% !important;
	}

	.cancel-collapse {
		padding: 10px 20px;
		margin: 10px 0 0;
		.panel-header {
			padding: 10px;

			.text {
				color: white;
			}
		}

		.unit-orders {
			padding: 20px 10px;
			margin-top: 5px;
			border-top: 1px solid #818181;
			.text {
				color: white;
				font-size: 18px;
				font-weight: bold;
			}
			.small-font {
				font-size: 16px;
			}
			.white-text {
				color: white;
			}
			.image-container {
				width: 70px;
				height: 70px;
			}
		}

		.buttons-bar {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			gap: 10px;
			padding: 20px 0 0;
			margin-top: 10px;
			border-top: 1px solid #818181;
		}
	}
}
