.order-accounts-manager-modal-container {
  .text-grey {
    color: #dee2e6;
  }

  .ant-calendar-picker {
    .ant-calendar-picker-input {
    }
  }

  .ant-badge {
    // width: 100%;
  }

  .custom-datePicker {
    background-color: #3b4853;
    border: 1px solid rgb(69, 82, 95);
    border-radius: 5px;
    width: 100%;
    height: 40px;
    padding-left: 10px;

    &:focus {
      outline: 0;
    }
  }
}

.order-accounts-manager-modal-container{
  .ant-badge {
    width : 100% !important;
  }
}