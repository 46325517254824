@font-face {
  font-family: "AvenirNext";
  src: local("AvenirNext"),
    url(../fonts/AvenirNextLTPro-Regular.otf) format("opentype");
}

.home-page-container {
  font-family: "Muli", sans-serif;
  font-size: 16px;

  .ant-layout-footer {
    background-color: white;
  }

  .ant-input-lg {
    background-color: white;
    border: 1px solid #adadad;
  }

  .text-color-1 {
    color: black;
  }
  .text-color-2 {
    color: #1abc9c;
  }
  .text-color-3 {
    color: white;
  }
  .bold-font {
    font-weight: 800;
  }
  .display-block {
    display: block;
  }

  // .font-size-1{
  //     font-size: 1.1em;
  // }
  #components-layout-demo-top .logo {
    width: 120px;
    height: 31px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px 24px 16px 0;
    float: left;
  }
  .logo {
    .ant-typography {
      color: black;
    }
  }

  .ant-layout-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 0.5px solid rgba(128, 128, 128, 0.233);
  }
  .ant-menu-horizontal {
    border: none;
  }
  .ant-menu-item {
    border-bottom: none;
    &:hover {
      border: none;
    }
  }
  .ant-menu-item-selected {
    border-bottom: none;
  }
  .ant-menu-item-active {
    border-bottom: none;
  }
  .demo-btn {
    border-color: #1abc9c;
    color: #1abc9c;
  }

  .ant-layout-content {
    background-color: white;
    padding: 0px !important;
    // padding: 0 50px;
  }

  .hero-section {
    //   min-height: 600px;
    background: linear-gradient(
      165deg,
      #00d1be2e,
      #00d1be2e 60%,
      white 60%,
      white
    );
    margin: 0px !important;
    padding: 0 50px;

    .ant-typography {
      margin: 0px;
      //   font-weight: bold;
    }

    .hero-img {
      width: 500px;
    }
  }

  .product-info-section {
    padding: 50px 100px;
    border-bottom: 0.5px solid #8a8a8a;
    margin: 0px !important;

    img {
      width: 80px;
      height: 80px;
    }
  }
  .timeline-section {
    padding: 50px;
    margin: 0px !important;
  }
  .ant-timeline-item {
    padding: 0 0 80px;
  }
  .ant-timeline-item-head {
    width: 20px;
    height: 20px;
  }
  .ant-timeline-item-tail {
    left: 9px;
  }

  .features-section {
    border-bottom: 0.5px solid #8a8a8a;
    background: linear-gradient(
      165deg,
      #00d1be2e,
      #00d1be2e 60%,
      white 60%,
      white
    );
    padding: 50px 100px;
  }

  .features-img {
    width: 600px;
  }
  .catalogue-container {
    border: 0.5px solid #1abc9c;
    border-radius: 5px;
    background-color: white;
  }

  .brand-section {
    padding: 50px 100px;
    .brand-logo {
      width: 140px;
    }
  }

  .omnycomm-info-section {
    padding: 50px 45px 0px 45px;
    margin: 0px !important;
    background: linear-gradient(25deg, #364155, #364155 60%, white 60%, white);
    .info-card {
      border-color: rgba(0, 0, 0, 0.09);
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
      min-height: 280px;
      img {
        width: 60px;
        height: 60px;
      }
      .ant-card-cover {
        display: flex;
        justify-content: center;
        padding: 20px;
      }
      .ant-card-body {
        background-color: #fff;
      }
      .ant-card-meta-title {
        text-align: center;
        font-weight: 900;
      }
      .ant-card-meta-description {
        text-align: center;
        color: #8a8a8a;
      }
    }

    .demo-container-parent {
      padding: 100px 50px 0px 50px !important;
      //   border: 1px solid #8a8a8a;
      //   border-radius: 5px;

      .demo-container {
        padding: 40px;
      }
    }
  }
  .info-footer-container {
    padding: 0px 100px;
    background-color: #f1f1f1;
    .info-footer {
      border-top: 1px solid #adadad;
      border-bottom: 1px solid #adadad;
      padding: 50px 0;
    }
  }
}
