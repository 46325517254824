
.carousel-container {
    .image-gallery-image {
        width: 100%;
        height: 50vh !important;
        background-color: white;
       
    }

    .image-gallery-thumbnail-image {
        width: 100%;
        height: 10vh !important;
        object-fit: contain;
        background-color: white;

    }
    .image-gallery-left-nav {
        padding: 0px;
    }
    .image-gallery-right-nav {
        padding: 0px;
    }

    .image-gallery-svg {
        height: 30px;
       
    }
    .image-gallery-icon {
        color: #36404A;
        font-weight: bold;
        stroke-width: 2;
        filter:none;
        &:hover {
            color: #1ABC9C !important;

        }
    }
    .image-gallery-thumbnail {
        &:hover {
        border: 2px solid rgba(26, 188, 156, 0.267) !important;
            
        }
    }
    .image-gallery-thumbnail.active{
        border: 2px solid #1ABC9C !important;
        &:hover {
        border: 2px solid #1ABC9C !important;

        }
    }

}