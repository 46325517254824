.dealshubadmincontainer {
  max-width: 95vw;
  padding: 0px 25px 0px 25px;
}



.categoriescontainer {
  background: #36404a;
  margin: 20px 0px;
  padding: 20px;

  .btn-wrapper {

    // margin: 10px 0px;
    .upload-btn {
      padding: 7px 10px;
      border-radius: 4px;
      height: 32px;
      margin-right: 10px;
      background-color: #6658dd;
      border: none;
      color: white;

      &:hover {
        background-color: #4938d7;
        color: white;
      }
    }

    padding: 0px;

    .upload-btn {
      .ant-upload {
        height: 32px !important;
      }
    }

    button {
      margin-right: 10px;
    }
  }

  // .ant-spin-nested-loading>div>.ant-spin {
  //   height: 100vh !important;
  //   max-height: none !important;

  //   .ant-spin-nested-loading>div>.ant-spin .ant-spin-dot {
  //     font-size: 40px !important;
  //   }
  // }

  .admin-sections-spinner {
    height: 100vh;
    margin-top: 40vh;
  }
}

.categorycontainer {
  background: #36404a;
  padding: 20px;
}

.ant-radio-group {
  label {
    display: block;
  }
}

.productslist {
  background: #303841;
  padding: 20px;
}

.categoryproductcard {
  width: 100%;
  position: relative;

  .ant-card-body {
    background: #3c4853 !important;
    padding: 10px !important;

    .ant-row {
      .ant-col {
        img {
          width: 100%;
          border-radius: 4px;
        }
      }
    }
  }
}

.ant-radio-group {
  label {
    margin: 5px 0px !important;
  }
}

.ant-card-bordered {
  border: 1px solid #45525f;
  border-radius: 4px;
}

.buttons {
  margin-top: 12px !important;

  .ant-col {
    button {
      margin: 0px 5px;
    }
  }
}

.wrapper-ul-row {
  padding-left: 0px;
}

.dealshub-admin-collapse-panel {
  .ant-collapse-header {
    display: flex;
    justify-content: space-between;
  }
}

.dealshub-admin-section-loader-container {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}