.order-invoice-modal-wrapper {
    width: 1024px;

    .ant-modal {
        width: 1024px;
    }

    .order-invoice-wrapper {
        background-color: #fff;
        padding: 20px !important;

        .order-invoice-inner-col {
            // border: 1px solid #000;
            border: 1px solid #e8e8e8;
            padding: 10px !important;

            .title {
                font-weight: 700;
            }

            .title-large {
                font-weight: 700;
                font-size: 15px;
            }
        }

        .table-wrapper {
            border: 1px solid #e8e8e8;
            padding: 20px !important;

            .title {
                font-weight: 700;
            }

            .title-large {
                font-weight: 700;
                font-size: 15px;
            }
        }

        .orders-listing-table-wrapper {

            .ant-col {
                border: 1px solid #000;
                padding: 10px;
            }
        }
    }
}

.title{
    color : black !important;
}

.normal-text{
    color : black !important;
}
.Tag{
    margin-right:-6px;
}

.noPad{
    padding-top : 0px !important;
    padding-bottom: 0px !important;
}

.add-order-button {
    width: 100%;
    background-color: #F1556C;
    color: white;
    border: none;
}

.add-order-button:hover {
    width: 100%;
    background-color: #F06F82;
    color: white;
}
.add-order-button:active {
    width: 100%;
    background-color: #F06F82;
    color: white;
}
.add-order-button:focus {
    width: 100%;
    background-color: #F06F82;
    color: white;
}