.orders-page-container {
    width: 95%;
    height: 90vh;
    padding: 0px 25px 0px 25px;


.base-product-container-1 {
    margin-bottom: 0px !important;

    .ant-collapse-item-active {
      border: 1px solid #1ABC9C;
      margin-bottom: 5px;

  
    }
  }



    .ant-tag {
        margin: 0 !important;
    }


    .margin-right {
        margin-right: 10px;
    }

    .orders-table {
        overflow: hidden;
        height: 100%;
        .orders-table-content {
          width: 100%;

          height: 70vh;
          overflow-y: scroll;

          /* width */
          &::-webkit-scrollbar {
            width: 5px !important;
            height: 30px !important;
          }
        
          /* Track */
          &::-webkit-scrollbar-track {
            background: #36404A;
          }
        
          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: #dee2e6;
          }
        
          /* Handle on hover */
          &::-webkit-scrollbar-thumb:hover {
            background: #1ABC9C;
          }
        }
        
    }

    .orders-table-container {
        background-color: #36404A;
        padding: 20px;
        
        

        .search-select {
            margin-right: 10px;
        }
    }

    .table-head-row {
        padding: 20px;
        background-color: #3F4A56;
        margin-top: 20px;
        margin-bottom: 5px;
        border-top: 1px solid #495561;
        border-bottom: 1px solid #495561;
        min-width: 900px;

        .table-head-text {
            color: #dee2e6;
        }
    }

    .orders-row {
        padding: 10px;
        background-color: #313841;
        margin-bottom: 5px !important;
        min-width: 900px;
        min-height: 100px;

        .table-content-text {
            color: #dee2e6;
        }

        .text-color-2 {
          color: white;
        }
    }
}

@media only screen and (max-width: 600px) {
    .orders-page-container {
        padding: 20px 10px 10px 10px;

        .top-nav {
            display: none;
        }

        .orders-table-container {
            padding-top: 0px;
        }
    }
}


.base-product-container {

    .ant-collapse-item-active {
      border: 1px solid #1ABC9C;
      margin-bottom: 5px;

  
    }
  }
