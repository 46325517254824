.body-text {
  font-size: 16px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 24px;
}



.location-group{
  .ant-checkbox-group-item {
    color: #F1F1F1
  }
  // .ant-checkbox-inner{
  //   background-color: #939EA9;
  // }
}

.modal {
  .ant-modal-content {
    width: fit-content;
  }
}

.user-rows-container {
  width: 100%;
  height: 70vh;
  overflow-y: scroll;

  /* width */
  &::-webkit-scrollbar {
    width: 5px !important;
    height: 10px !important;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #36404A;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #dee2e6;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #1ABC9C;
  }
}
.user-permissions-container {
  width: 100%;
  height: 60vh;
  overflow-y: scroll;

  /* width */
  &::-webkit-scrollbar {
    width: 5px !important;
    height: 10px !important;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #36404A;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #dee2e6;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #1ABC9C;
  }
}
