.picked-btn {
  color: white;
  background-color: #8dc153;
  border: none;

  &:hover {
    color: white;
    background-color: #8dc153;
    border: none;
  }
}

.orders-warehouse-manager-page-container {
  // width: 95vw;
  width: 100%;
  padding: 0px 25px 0px 25px;

  .margin-zero {
    margin: 0px !important;
  }

  .text-grey {
    color: #dee2e6;
  }

  .dispatched-btn {
    background-color: #68bb59;
    /* width:"100%"; */

    color: white;
    border: none;

    &:hover {
      background-color: #68bb59;
      color: white;
      border: none;
    }
  }

  .returned-btn {
    background-color: #ff8291;
    /* width:"100%"; */

    color: white;
    border: none;

    &:hover {
      background-color: #ff8291;
      color: white;
      border: none;
    }
  }

  .failed-btn {
    background-color: #f1556c;
    /* width:"100%"; */

    color: white;
    border: none;

    &:hover {
      background-color: #f1556c;
      color: white;
      border: none;
    }
  }

  .picked-btn {
    background-color: #acdf87;
    /* width:"100%"; */

    color: white;
    border: none;

    &:hover {
      background-color: #acdf87;
      color: white;
      border: none;
    }
  }

  .delivered-btn {
    background-color: #4c9a2a;
    /* width:"100%"; */

    color: white;
    border: none;

    &:hover {
      background-color: #4c9a2a;
      color: white;
      border: none;
    }
  }

  .failed-btn {
    background-color: #f1556c;
    /* width:"100%"; */

    color: white;
    border: none;

    &:hover {
      background-color: #f1556c;
      color: white;
      border: none;
    }
  }

  .top-nav {
    margin-bottom: 20px;
  }

  .orders-page-content-container {
    height: 65vh;
    overflow-y: scroll;

    .no-orders-wrapper {
      margin: 20vh auto;
      max-width: 409px;
      color: #fff;
    }

    /* width */
    &::-webkit-scrollbar {
      width: 5px !important;
      height: 30px !important;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #36404a;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #dee2e6;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #1abc9c;
    }
  }

  .ant-tag {
    margin: 0 !important;
  }

  .margin-right {
    margin-right: 10px;
  }

  .customers-table {
    // overflow: hidden;
    padding: 0px 20px 20px 20px;
    background-color: #36404a;
  }

  .orders-warehouse-manager-table-container {
    background-color: #36404a;
    padding: 20px;
    // height: 90vh;
    // overflow: scroll;

    /* width */
    &::-webkit-scrollbar {
      width: 5px !important;
      height: 30px !important;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #313841;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #dee2e6;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #1abc9c;
    }
  }

  .table-head-row {
    padding: 20px;
    background-color: #3f4a56;
    // margin-top: 20px;
    margin-bottom: 5px;
    border-top: 1px solid #495561;
    border-bottom: 1px solid #495561;
    min-width: 900px;

    .table-head-text {
      color: #dee2e6;
    }
  }

  .orders-row {
    padding: 20px;
    min-width: 900px;

    background-color: #313841;
    margin-bottom: 5px;

    .table-content-text {
      color: #dee2e6;
    }
  }
}

.active-class {
  // background-color: green;
  color: #1abc9c;
  border: 1px solid #1abc9c;
  // #1ABC9C
}

.shipping-method-wrapper {
  text-align: center;
}

// .orders-warehouse-manager-mobile-view-page-container {
//   display: none;
// }

// .orders-warehouse-manager-page-container {
//   display: none;
//   padding: 10px;
//   // overflow: hidden;
//   margin-bottom: 10px;
// }

@media only screen and (max-width: 1279px) {
  .orders-warehouse-manager-page-container {
    display: none;
    padding: 10px;
    // overflow: hidden;
    margin-bottom: 10px;
  }

  .orders-warehouse-manager-mobile-view-page-container {
    .ant-collapse
      > .ant-collapse-item
      > .ant-collapse-header
      .ant-collapse-extra {
      float: right !important;
    }

    display: block;
    width: 100%;
    padding: 10px;
    overflow: hidden;

    .ant-typography {
      margin: 0px;
    }

    .title-container {
      text-align: left;
    }

    .main-title {
      color: #dee2e6;
      margin: 0px;
    }

    .filter-row-container {
      // padding: 15px !important;
      overflow-x: scroll;
      margin-bottom: 10px;

      /* width */
      &::-webkit-scrollbar {
        width: 0px !important;
        height: 0px !important;
      }
    }

    .filter-row {
      width: 650px;
    }

    .collapse-header {
      padding: 10px;
      background-color: #36404a;
      border: 1px solid #495561;
      margin: 0px !important;
    }

    .order-container {
      margin: 0px !important;
    }

    .ant-collapse-content-box {
      border: 1px solid #495561;
      border-top: none;
      padding-top: 2px;
    }

    .ant-collapse-item-active {
      border: 1px solid rgb(26, 188, 156);
    }

    .order-row {
      padding: 10px !important;
      background-color: #36404a;
      // border: 1px solid #495561;
      margin-bottom: 2px;
      margin-top: 2px;
    }

    .billing-container {
      padding: 0px !important;
      background-color: #36404a;
      margin: 0px;
    }
  }
}

.ant-empty-normal .ant-empty-image {
  height: 100%;
}

@media (max-width: 480px) {
  .collapse-container-warehouse-manager {
    clear: both;
  }
  .warehouse-manager-search-bar-container {
    width: 80%;
  }
}
