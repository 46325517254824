.proforma-invoice-page-container {
    width: 95vw;
    padding: 0px 25px 0px 25px;

    .ant-tag {
        margin: 0 !important;
    }
   

    .margin-right {
        margin-right: 10px;
    }
    .proforma-table {
        overflow: scroll;

    }
    .ant-collapse-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .proforma-invoice-table-container {
        background-color: #36404A;
        padding: 20px;
        height: 90vh;
        overflow: hidden;
    }
    .top-nav {
        margin-bottom: 20px;
    }
    .collapse {
        padding: 20px;
        background-color: #313841;
        // margin-top: 20px;
        margin-bottom: 5px;
       
        min-width: 900px;

        .product-table-head-row {
            padding: 20px;
            background-color: #3F4A56;
            margin-top: 20px;
            border-top: 1px solid #495561;
            border-bottom: 1px solid #495561;
        }
        .product-row {
            padding: 20px;
            background-color: #36404A;
            margin-top: 5px;
        }

        .table-head-text {
            color: #dee2e6;
        }
        .table-content-text {
            color: #dee2e6;

        }
    }

    

}
        @media only screen and (max-width: 600px) {
            .proforma-invoice-page-container {
                padding: 20px 10px 10px 10px;
                .top-nav {
                    display: none;
                }
                .proforma-invoice-table-container{
                    padding-top: 0px;
                }
            }
        }




