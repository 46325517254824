.responsiveImage{
    width: 400px;
    height: 400px;
    background-color: #3C4853;
}

.ant-empty-description{
    color:white;
}

@media screen and (max-width: 768px) {
    .responsiveImage{
        width: 300px;
        height: 300px; 
    }
  }

  @media screen and (max-width: 1024px) {
    .responsiveImage{
        width: 200px;
        height: 200px;
      
    }
  }

.image-list {
    .ant-upload-list {
        height: fit-content;
        max-height: 250px;
        overflow: scroll;
    }
    .ant-upload.ant-upload-select-picture-card {
        margin-top: 10px;
    }
    .ant-modal {
        width: fit-content
    }
    .ant-modal-content {
        width: fit-content !important;
    }
}