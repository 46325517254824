.column-button {
	background-color: #313841;
	padding: 10px;
	border: 1px dashed #1abc9c;
	color: #1abc9c;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	margin: 10px 0;
	border-radius: 10px;
	font-size: 18px;
	cursor: pointer;
	transition: all 0.3s ease-in-out;

	&:hover{
		background-color: #1abc9c;
		color: white;
		border: 1px solid #1abc9c;
	}
}

.column-bar-container {
	overflow-y: scroll;
	height: 65vh;

	.selected {
		display: flex;
		color: #69caaa !important;
		background-color: #1abc9c2f;
		align-items: center;
		justify-content: space-between;
	}

	.column-bar {
		padding: 10px;
		color: white;
		font-size: 17px;
		cursor: pointer;
		&:hover {
			background-color: rgba(255, 255, 255, 0.2);
		}
	}
}
