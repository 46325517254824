.upload-docs{
    &-title{
        font-size: 16px;
        color: white;
        margin: 10px 0;
    }
    &-radio{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        &-group{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap:20px;
            width: 100%;
            &-item{
                color: #1abc9c;
                font-size: 16px;
            }
        }
    }
    &-upload{
        margin: 10px 0 0;
        &-button {
            .ant-upload,
            .ant-upload-select,
            .ant-upload-select-picture-card {
                padding: 0 !important;
                margin: 0 !important;
                width: fit-content !important;
                height: fit-content !important;
            }
            padding: 0px !important;
            margin: 0px !important;
        }
    }
    &-divider{
        margin: 10px 0;
    }
}