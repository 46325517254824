.profilepagecontainer {
    width: 95vw;
    padding: 0px 25px 0px 25px;
}



.profilecard {
    background-color: #37414B;
    padding: 20px;
}

.username {
    margin-top: 6px;

    .ant-col {
        .ant-typography {
            border-radius: 50%;
            padding: 20px;
            border: 1px solid #fff;
            line-height: 150px;
            font-size: 30px;
            font-weight: 700;
        }
    }
}

.usernametagline {
    line-height: 12px;

    .ant-col {
        .ant-typography {
            font-size: 12px;
        }
    }
}

.aboutme {
    margin-top: 20px;
}

.details {
    margin-top: 16px !important;
}

.contacts {
    margin-top: 16px !important;
}

.activity-log {
    background-color: #37414B;
    padding: 20px;
}

.inputprofileform {
    margin-top: 20px !important;
}

.permission-label-row {
    margin-bottom: 10px !important;
}

.activity-history-table-container {
    margin-top: 20px;
}


@media only screen and (max-width: 600px) {

    .profilecard {
        margin-top: 20px;
    }
    .profilepagecontainer {
        padding: 0 10px;
    }
}

@media only screen and (max-width: 1000px) {
    .profilecard {
        // margin-top: 40px;
    }
}