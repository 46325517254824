body{
  color:white;
}

.dealshubadmincontainer {
    width: 95vw;
    padding: 0px 25px 0px 25px;
    margin-top: 2em;
  }

  .dealshub-admin-collapse-panel {
    .ant-collapse-header {
      display: flex;
      justify-content: space-between;
      padding: 20px;
    }
  }