.main {
  position: fixed;
  background-color: rgb(248, 248, 248);
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  padding: 10px 15px;
  border-radius: 10px;
  color: #36404a;
  box-shadow: 0px 15px 20px 2px rgba(0, 0, 0, 0.56);

  .refresh {
    color: #1abc9c;
    cursor: pointer;
  }

  .close {
    position: relative;
    bottom: 10px;
    right: -5px;
    cursor: pointer;
    svg {
      font-size: 10px;
      font-weight: bolder;
    }
  }
}
