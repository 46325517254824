.blog-analytics {
  padding: 16px 20px 20px 20px;
  background-color: #36404A;
  border-radius: 4px;
}

.analytics-data {
  font-size: 28px;
  font-weight: bold;
  color: #FFFFFF;
}

.cover-img {
  height: 50px;
  width: 50px;
  background-color: white;
  border-radius: 8px;
}

.article-container {
  width: 100%;
  height: 70vh;
  overflow-y: scroll;

  /* width */
  &::-webkit-scrollbar {
    width: 0px !important;
    height: 10px !important;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #36404A;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #dee2e6;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #1ABC9C;
  }
}

.blog-text {
  //font-size: 16px;
  font-weight: 600;
  color: #FFFFFF;
}

.input-header {
  font-weight: 600;
  color: #DEE2E6;
}

.cover-img-container {
  margin-top: 10px;
  height: 105px !important;
  width: 100%;
}

.ant-upload.ant-upload-drag .ant-upload-drag-container {
  vertical-align: top;
}

.preview-title {
  font-size: 45px;
  font-weight: bold;
  color: #2D3748;
}

.preview-author {
  color: #2D3748;
  font-size: 18px;
  font-weight: bold;
}

.preview-time {
  color: #718096;
  font-size: 18px;
  margin-left: 20px !important;
}

.rdw-editor-toolbar {
  margin-bottom: 0px !important;
  border-radius: 0px !important;
}

.rdw-editor-main {
  background-color: #FFFFFF !important;
  height: 296px !important;
}
.rdw-image-modal {
  left: -35px
}

//.ant-card .ant-card-body{
//  padding: 0px 10px 5px 10px !important;
//}


a:hover {
  color: black !important;
}
.rdw-editor-wrapper {
  margin-top: 12px;
}


.preview-content {
  color: #718096;
  font-size: 16px;
}