.img-url-wrapper {
    margin: 20px 10px;

    p {
        color: #fff;
    }

    .modal-submit-btn {
        margin-top: 20px;
    }
}

.btn-container-generic-banner {
    margin-bottom: 18px;
}

.generic-banner-modal-content {

    .added-products-listing {
        .promotional-product-price-wrapper {
            margin: 12px 0px;
        }

        .ant-spin-nested-loading {
            min-height: 100px;
        }

        .ant-spin-nested-loading>div>.ant-spin .ant-spin-text {
            color: #fff;
            font-weight: bold;
            text-shadow: none;
        }
    }

    .ant-calendar-range-picker-input:placeholder-shown {
        cursor: default;
    }

    .ant-calendar-picker-icon {
        color: #fff;
        cursor: pointer;
    }

    .products-price-wrapper {
        color: #fff;
    }
}