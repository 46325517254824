th {
  border: 1px solid #495561 !important;
}

tr {
  border: 1px solid #495561 !important;
}

td {
  border: 1px solid #495561 !important;
}

table {
  border: 1px solid #495561 !important;
}
#enlarged-image {
  position: absolute;
  top: 25%;
}
.product-details-container {
  .brand-name-product {
    color: #1abc9c;
  }
  &:hover {
    color: #6658dd;
  }
}

.product-details-form {
  width: 95%;
  height: fit-content;
  
  .ant-form-item-label {
    line-height: normal;
  }
  .ant-form-item-label > label {
    color: #f1f1f1;
    font-size: 14px;
  }
  .ant-select-selection {
    background: #454e57;
  }
  .basic-info {
    .ant-form-item-label > label.ant-form-item-no-colon::after {
      content: "*";
      font-weight: bold;
      color: red;
    }
  }
  .ecommerce-info {
    .ant-form-item-label > label.ant-form-item-no-colon::after {
      content: "*";
      font-weight: bold;
      color: #009429;
    }
  }
  .rich-info {
    .ant-form-item-label > label.ant-form-item-no-colon::after {
      content: "*";
      font-weight: bold;
      color: #0066FF;
    }
  }
  .ant-select {
    color: #B9BAba;
    background-color: #313841;
    margin-top: 5px;
  }
  .ant-form-item {
    margin-bottom: 16px;
  }
  .ql-snow .ql-picker-label {
    display: grid;
  }
  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    border: none;
    height: 242px;
  }
  .quill .ql-editor {
    background-color: #454e57;
    color: white;
  }
  .ql-editor.ql-blank::before {
    font-style: normal;
    color: #bfbfbf;
  }
  .input-field::placeholder{
    color: #858686;
  }
  .quill {
    border: none;
    background-color: #36404a;
  }
  .ql-toolbar.ql-snow {
    border: none;
  }
  .ant-tabs-ink-bar {
    height: 3px;
    background: white !important;
  }
  .ant-tabs-nav .ant-tabs-tab {
    padding: 12px 16px 12px 0px;
    color: #a49f9f;
  }
  .ant-tabs-nav .ant-tabs-tab:hover {
    color: white !important;
  }
  .ant-tabs-nav .ant-tabs-tab-active {
    color: white !important;
  }
  .ant-tabs-bar {
    margin: 0px;
  }
}

@media only screen and (max-width: 600px) {
  .size-label {
    margin-bottom: 15px;
    margin-left: 2px;
  }
  #enlarged-image {
    display: none;
  }
}

.loader-container {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}