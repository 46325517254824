// Import a Google Font
// @import url("https://fonts.googleapis.com/css?family=Nunito:400,700");

// =========================
// Variables
// =========================

$font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans,
  Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
$primary: #521893;
$primary-dark: #a3a3a30f;
$primaryLight1: #58bbb8;
$primaryLight2: #01c7c3;
$secondary: #141414;
$secondaryLight: #01112f;
$secondaryDark: #010d26;
$text: black;
$text2: #521893;
$text3: red;
$textDark: #565555;
$accent: #e1461a;
$bg: white;
$bg-grey: grey;
$bg-purple: #521893;
$bgTransparent: transparent;
$itemGrow: 1.2;
$duration: 250ms;
