@media only screen and (max-width: 1000px) {
  .hide-on-small-breadcrumb {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .title-with-breadcrumbs {
    display: none;
  }
}
