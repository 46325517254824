.factories-page-container {
  width: 95vw;
  padding: 0px 25px 0px 25px;

  .ant-tag {
    margin: 0 !important;
  }
  .top-nav {
    margin-bottom: 20px !important;
    padding-left: 10px;
  }
  .text-color-1 {
    color: #dee2e6;
  }

  .factories-table-container {
    background-color: #36404a;
    padding: 20px;
    height: 90vh;
    overflow: hidden;
    .factories-table {
      overflow: scroll;
      padding : 0px 10px;
      .factory-card {
        padding: 5px !important;
        .factory-card-content {
          background-color: #313841;
          padding: 15px !important;
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .factories-page-container {
    padding: 20px 10px 10px 10px;
    .top-nav {
      display: none;
    }
    .factories-table-container {
      padding-top: 0px;
    }
  }
}

.factory-avatar {
  img {
    object-fit: contain;
    background: white;
  }
}
