// .second-toolbar
// {
//     display: none;
// }

// .fr-toolbar{
//     background-color: #485561;
//     color:#8a8a8a;
//     border : 1px solid rgb(69, 82, 95);
// }

// .fr-element{
//     color:#8a8a8a !important;
// }

// .fr-wrapper{
//     background-color: #3C4853 !important;
//     border-radius: 0px 0px 10px 10px;
//     border : 1px solid rgb(69, 82, 95) !important;
// }

// .fr-more-toolbar{
//     background-color: #36404A !important;
// }

// .fr-newline{
//     background-color:#485561!important;
// }

// .fr-toolbar{
//     fill: red;
// }


// .editorBox-container {
//     .quill-container {
//         .quill {
//             background-color: #3B4853;
//             border: 1px solid rgb(69, 82, 95);

//             .ql-picker-label {
//                 color: black;
//                 background-color: white;
//             }
//             .ql-formats {
//                 color: black;
//                 background-color: white;
//             }
//             .ql-editor {
//                 background-color: white;
//             }
            
//         }
//     }
// }

.quill {
    background-color: #3B4853;
    border: 1px solid rgb(69, 82, 95);
    height: 150px;

    .ql-picker-label {
        color: black;
        background-color: white;
    }
    .ql-formats {
        color: black;
        background-color: white;
    }
    .ql-editor {
        background-color: white;
    }
    
}

.ql-editor{
    min-height : 100px;
}
.react-quill-component {

}