

@media only screen and (max-width: 600px) {
    .exports-page-wrapper {
        margin-top: 10px !important;
    }
}

.v-r-p-error{
    color : #f5222d;
    font-weight: bold;
}

.v-r-p-success{
    color : #52c41a;
    font-weight: bold;
}

.v-r-p-warning{
    color : #faad14;
    font-weight: bold;
}

.v-r-p-table-rows{
    background-color: #313841;
    padding: 10px 20px 10px 20px;
    margin-bottom: 5px;
    position: relative;
}

.v-r-p-status{
    position: absolute;
    top: 7px;
    right: 10px;
}

.smallFont {
    font-size: 10px;
}

.v-r-p-col{
    line-height: 12px;
}

.padright-10{
    padding-right: 10px !important;
}

.custom-datePicker-small {
    background-color: #3b4853;
    border: 1px solid rgb(69, 82, 95);
    border-radius: 5px;
    width: 100%;
    height: 32px;
    padding-left: 10px;

    &:focus {
      outline: 0;
    }
  }

  th,td,tr,table{
      border: none !important;
  }